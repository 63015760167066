import React from 'react';
import SEO from '../../components/seo';
import CategoryPage from '../../components/desktop/Categories/CategoryPage';
import {graphql, StaticQuery} from 'gatsby';

const Category = () => {
  return (
    <>
      <SEO title={'Marshall Jozef Pilsudski'} />
      {/*<StaticQuery*/}
      {/*  query={graphql`*/}
      {/*    query {*/}
      {/*      api {*/}
      {/*        arts {*/}
      {/*          title {*/}
      {/*            en*/}
      {/*          }*/}
      {/*          titlePage*/}
      {/*          photos*/}
      {/*          category*/}
      {/*          thumbnail*/}
      {/*        }*/}
      {/*      }*/}
      {/*    }*/}
      {/*  `}*/}
      {/*  render={(data) => (*/}
      {/*    <CategoryPage*/}
      {/*      data={data.api.arts}*/}
      {/*      categoryId={2}*/}
      {/*      location="en"*/}
      {/*      bgColor="#E19087"*/}
      {/*    />*/}
      {/*  )}*/}
      {/*/>*/}
    </>
  );
};

export default Category;
